import React from "react";
import ProjectsList from "./ProjectsList";

export default function Projects() {
  return (
    <>
      <main>
        <section className="flex-row">
          <ProjectsList />
        </section>
      </main>
    </>
  );
}
