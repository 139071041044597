import React from "react";

export default function NotFound() {
  return (
    <>
      <main className="full-height flex-center">
        <h2>ERROR 404</h2>
      </main>
    </>
  );
}
